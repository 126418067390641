import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

import { Address, GoogleListing, SocialAddress } from '../components/address';
import { Content } from '../components/content';
import { Hero } from '../components/hero';
import * as imageStyles from '../components/image.module.css';
import { Layout } from '../components/layout';
import { connectWithUs, contactUs, leaveUsAReview } from '../content';

const ContactPage = () => {
  return (
    <Layout
      title="Bridge & Associates Appraisals Ltd.: How to reach us"
      description="How to contact Bridge & Associates Appraisals Ltd. This page includes our email, phone number, LinkedIn, and Facebook."
    >
      {({ setIsMenuOpen }) => (
        <>
          <Hero
            slogan="How to get in touch with us"
            setIsMenuOpen={setIsMenuOpen}
            image={
              <StaticImage
                loading="eager"
                alt="Close-up of a hand dialing a number on a phone"
                layout="fullWidth"
                aspectRatio={1}
                className={imageStyles.background}
                src={'../images/contact.jpg'}
              />
            }
          >
            <Content content={contactUs} withPaddingTop>
              <Address />
            </Content>
            <Content content={connectWithUs} withPaddingTop opaque>
              <SocialAddress />
            </Content>
            <Content content={leaveUsAReview} withPaddingTop opaque>
              <GoogleListing />
              <StaticImage
                alt="A couple standing together in their kitchen"
                className={imageStyles.inlineImage}
                aspectRatio={16 / 9}
                src="../images/clients.jpeg"
              />
            </Content>
          </Hero>
        </>
      )}
    </Layout>
  );
};

export default ContactPage;
