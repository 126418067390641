import React from 'react';

import * as addressStyles from './address.module.css';

export const Address = () => {
  return (
    <address className={addressStyles.address}>
      <a className={addressStyles.anchor} href="mailto:cmb.appraiser@gmail.com">
        &#9993; cmb.appraiser@gmail.com
      </a>
      <a className={addressStyles.anchor} href="tel:+12502405950">
        &#9743; (250) 240-5950
      </a>
    </address>
  );
};

export const SocialAddress = () => {
  return (
    <address className={addressStyles.address}>
      <a
        className={addressStyles.anchor}
        href="https://www.linkedin.com/company/bridge-associates-appraisals-ltd"
      >
        ➝ LinkedIn
      </a>
      <a
        className={addressStyles.anchor}
        href="https://www.facebook.com/Bridge-Associates-Appraisals-393001271065239/"
      >
        ➝ Facebook
      </a>
    </address>
  );
};

export const GoogleListing = () => {
  return (
    <address className={addressStyles.addressG}>
      <a
        className={addressStyles.anchor}
        href="https://goo.gl/maps/fLAY7ELYdAGfL39t8"
      >
        ➝ Google Maps
      </a>
    </address>
  );
};
